import React from 'react';
import Landing from '../components/Landing';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Modalités et délai d'accès

**Dernière mise à jour : le 06/05/2024**

## Modalités d'accès aux formations

Aucun diplôme préalable n'est requis. Nos formations diplômantes sont ouvertes à tous !

Les inscriptions sont possibles de manière permanente (sous réserve de places disponibles). En postulant sur notre site Internet, vous sélectionnez la session de votre choix. Dans le cas du Bootcamp Développeur Web et Mobile, vous recevrez en retour un test de logique à réaliser afin de vérifier votre admissibilité en formation. Pour tous les cursus, un entretien téléphonique de 20-30 minutes sera réalisé afin de vérifier que votre projet est en adéquation avec la formation proposée.

Il vous faudra comprendre l'anglais un minimum, aussi (pas de panique, nos cours sont bien tous en français, mais nous pourrons vous conseiller de lire certaines ressources en anglais au cours de votre cursus).

## Délais d’accès

### Financement personnel / entreprise

Si le test de logique est validé, l’inscription à la session choisie est validée dès versement de l’acompte de 2 000 € par prélèvement bancaire ou le règlement de la formation par virement bancaire à réception de facture pour un financement entreprise et avant le début de la formation (sous réserve de places disponibles).

### Financement OCPO / France Travail / Région / Transitions Pro

Si le test de logique est validé, l’inscription à la session choisie est ensuite dépendante du délai de traitement de la demande de prise en charge. Elle sera définitive à réception de l’accord écrit (sous réserve de places disponibles).

Nous conseillons d’effectuer les demandes le plus tôt possible après admission en formation.

## Personnes en situation de handicap

Si vous êtes en situation de handicap, nous vous remercions de bien vouloir nous en informer afin que nous puissions vous accompagner dans votre projet de formation.

Sachez que nos formations en présentiel sont également dispensées à distance, ce qui permet aux personnes à mobilité réduite (PMR) de suivre la formation depuis chez eux.

Notre contact référent handicap : **contact**[arobase]**lereacteur**[point]**io**.

`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Modalités et délai d'accès"
      metaTitle="Modalités et délai d'accès | Le Reacteur"
    />
  );
});

export default Page;
